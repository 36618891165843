<template lang="pug">
  .sync-list-modal
    TopBar(
      :item-title="$t('sync_list.car_class_name')"
      :item-name="carClass.name"
      edit-link-name="CarClassMatching"
    )
    SyncedItem.m-t-30(
      :synced-item-title="$t('sync_list.synced_car_class')"
      :sources-items="carClass.sources_car_classes"
    )
</template>

<script>
  export default {
    components: {
      TopBar: () => import("@/components/SyncList/TopBar"),
      SyncedItem: () => import("@/components/SyncList/SyncedItem"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    props: {
      carClass: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  .sync-list-modal
    width: 600px
</style>
